import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Blog, Profile, CategoryBlog, NoMatch, BlogDetail } from './pages';
import { Navbar } from './components';
import './App.css';

const App = () => {
  return (
    <div>
      <BrowserRouter>
      <div>
        <Navbar /> 
        <div>
          <Routes>
            {/* dashboard  */}
            <Route path="/" element={(<Blog />)} />
            <Route path="/blog" element={(<Blog />)} />
            <Route path="/blog/category/:cat" element={(<CategoryBlog />)} />
            <Route path="/blog/:id" element={(<BlogDetail />)} />
            <Route path="/profile" element={(<Profile />)} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </div>   
        </div>
      </BrowserRouter>
    </div>
  )
}

export default App
