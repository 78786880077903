import React from 'react';
import { Hero, About, Experience, Tech, Works } from '../components';

const Profile = () => {
  return (
    <div className="mx-auto px-4 lg:px-24 2xl:px-48 mt-12 pt-12 bg-slate-400">
          <Hero />
          <div className="mb-24">
            <About />
          </div>
          <div className="mb-24">
            <Experience />
          </div>
          <Tech />
          <div className="mt-24 pb-8">
            <Works />
          </div>
    </div>
  )
}

export default Profile