export const posts = [
  {
    id: 1,
    title: "Using the State Hook",
    categories: ["react"],
    path:"tutorial1.md",
    image: "/assets/img/cover.jpeg",
    createdAt: "2023-4-12",
    author: "Chris"
  }, 
  {
    id: 2,
    title: "Equivalent Class Example",
    categories: ["react", "javascript"],
    path:"tutorial2.md",
    image: "/assets/img/cover.jpeg",
    createdAt: "2023-4-16",
    author: "Chris"
  },
];

export const categories = [
  {
    id: 1,
    title: 'react',
  },
  {
    id: 2,
    title: 'javascript',
  }
]