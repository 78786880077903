import React from 'react'
import { categories } from '../assets/data/data';
import { Link } from 'react-router-dom';

const Categories = () => {
  return (
    <div className="mt-8">
      <h2 className="font-bold text-2xl mb-2 border-b">Categories</h2>
      <Link to={`/`} className="block text-gray-700 text-lg pl-2 hover:font-bold hover:text-amber-700 capitalize mt-4" key={"home"}>
        All
      </Link>
      {categories.map((category) => (
        <Link to={`/blog/category/${category.title}`} className="block text-gray-700 text-lg pl-2 hover:font-bold hover:text-amber-700 capitalize mt-4" key={category.id}>
          {category.title}
        </Link>
      ))}
    </div>
  )
}

export default Categories