import React from 'react';
import { useParams } from 'react-router-dom';

import { Categories, PostWidget, Article} from '../components';
import { posts } from '../assets/data/data';

const BlogDetail = () => {

  const { id: postId } = useParams()

  const post = posts.filter((post) => {
    return post.id === parseInt(postId)
  })[0]

  return (
    <div className="mx-auto px-4 lg:px-24 2xl:px-48 mt-36">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
        <div className="lg:col-span-8 col-span-1">
          <Article post={post} />
        </div>
        <div className="lg:col-span-4 col-span-1 flex flex-top justify-center">
          <div className="lg:sticky relative top-8">
            <PostWidget />
            <Categories />
          </div>
        </div>

      </div>
    </div>
  )
}

export default BlogDetail