import React from 'react'

import { styles } from '../styles/styles';

const Hero = () => {
  return (
    <section className="relative w-full h-[350px] xxs:h-[250px] xs:h-[200px] sm:h-[250px] lg:h-[350px] mx-auto mb-24">
      <div className="absolute inset-0 top-[120px]">
        <div>
          <h1 className={`${styles.heroHeadText} text-black`}>
            Hi, I'm <span className='text-[#ff7409]'>Chris</span>
          </h1>
          <p className={`${styles.heroSubText} mt-2 text-white-100`}>
            I develop Fullstack web applications <br className='sm:block hidden' />using React and Django.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Hero