import React, { useState, useEffect } from "react"
import Markdown from "markdown-to-jsx"
import Code from "./Code"
import moment from 'moment';
import { LogoIcon } from '../assets/icons';

import '../styles/Post.css'

const Article = ({ post }) => {
  const [postContent, setPostcontent] = useState('')
  const [isDark, setIsDark] = useState(true)
  const fileName=post.path

  useEffect(() => {
    import(`../tutorials/${fileName}`)
      .then(res =>
        fetch(res.default)
          .then(response => response.text())
          .then(response => setPostcontent(response))
          .catch(err => console.log(err))
      )
  }, [fileName])

  return (
    <div className="article-wrapper">
      <article>
        <header>
          <div className="article__cover">
            <img
              src={post.image}
              alt="my-cover"
            />
          </div>
        </header>
        <div className="block lg:flex text-center items-center justify-center mb-2 w-full">
          <div className="flex items-center justify-center mb-4 lg:mb-0 w-full lg:w-auto mr-8">
            <LogoIcon />
            <p className="inline align-middle text-gray-700 ml-2 text-lg">{post.author}</p>
          </div>
          <div className="font-medium text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-2 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <span>
              {moment(post.createdAt).format('MMM DD, YYYY')}
            </span>
          </div>
        </div>
        <div className="text-center text-lg text-gray-700 font-normal px-4 lg:px-20 mb-8">
          {post.categories.map((category) => (
            <span className="inline-block rounded-lg bg-amber-600 text-sm font-medium text-white px-4 mx-2" key={category}>
              {category}
            </span>
          ))}
        </div>
        <main>
          <Markdown
            options={{
              overrides: {
                Code: {
                  component: Code,
                  props: {
                    isDark,
                    setIsDark
                  }
                }
              }
            }}
          >
            {postContent}
          </Markdown>
        </main>
      </article>
    </div>
  )
}

export default Article