import {
  mobile,
  backend,
  creator,
  web,
  javascript,
  html,
  css,
  reactjs,
  redux,
  tailwind,
  nodejs,
  mongodb,
  git,
  docker,
  tribbute,
  django,
  python,
  gcp,
  kubernetes,
  automax,
  travelapp,
} from "../";

const services = [
  {
    title: "Web Developer",
    icon: web,
  },
  {
    title: "Python Developer",
    icon: mobile,
  },
  {
    title: "Backend Developer",
    icon: backend,
  },
  {
    title: "Cloud Developer",
    icon: creator,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Python",
    icon: python,
  },
  {
    name: "Django",
    icon: django,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "Google Cloud Platform",
    icon: gcp,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "docker",
    icon: docker,
  },
  {
    name: "kubernetes",
    icon: kubernetes,
  },
];

const experiences = [
  {
    title: "Contract Programmer",
    company_name: "Tribbute",
    icon: tribbute,
    iconBg: "#97aaf7",
    date: "Jun 2018 - May 2019",
    points: [
      "Developing and maintaining Google Ads script using Javascript.",
      "Developing and maintaining Bing Advertising script.",
      "Collaborating with others and providing constructive feedback to others.",
    ],
  },
  {
    title: "Software Engineer",
    company_name: "Tribbute",
    icon: tribbute,
    iconBg: "#97aaf7",
    date: "May 2019 - Present",
    points: [
      "Responsible for the ongoing development and maintenance of TRIBBUTE's suite of automation products in Google, Bing, and Facebook.",
      "Responsible for supporting requests from the Data Analytics and Reporting Manager to help build and sustain ongoing reporting requirements for clients and internally.",
      "Developing and maintaining Airbyte and build data integration pipelines",
      "Developing and maintaining web application using React.js, Django and other related technologies for internal uses",
      "Designing and implementing modern, scalable data solutions using a range of new and emerging technologies from the Google Cloud Platform",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Sara Lee",
    designation: "CFO",
    company: "Acme Co",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Lisa Wang",
    designation: "CTO",
    company: "456 Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "AutoMax",
    description:
      "AutoMax is a website where people can see the available cars. They can also create their own listings for other people to buy.",
    tags: [
      {
        name: "django",
        color: "blue-text-gradient",
      },
      {
        name: "python",
        color: "green-text-gradient",
      },
      {
        name: "postgres",
        color: "pink-text-gradient",
      },
    ],
    image: automax,
    source_code_link: "https://github.com/shinchri/automax",
  },
  {
    name: "Travel App",
    description:
      "The web application takes a date and city as an input, and displays the weather information and countdown of the days that are left until the travel.",
    tags: [
      {
        name: "javascript",
        color: "blue-text-gradient",
      },
      {
        name: "scss",
        color: "green-text-gradient",
      },
      {
        name: "express",
        color: "pink-text-gradient",
      },
    ],
    image: travelapp,
    source_code_link: "https://github.com/shinchri/travel-app",
  },
];

export { services, technologies, experiences, testimonials, projects };