import React from 'react'
import { useParams } from 'react-router-dom';

import { Categories, PostWidget, PostCard } from '../components';
import { posts } from '../assets/data/data'

const CategoryBlog = () => {
  const { cat } = useParams();

  return (
    <div className="mx-auto px-4 lg:px-24 2xl:px-48 mt-36">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
        <div className="lg:col-span-8 col-span-1">
          <h1 className="text-5xl mb-8 underline font-bold capitalize">Category - {cat}</h1>
          {posts.slice(0).reverse().filter((post) => post.categories.some(category => category === cat)).map((post) => (
              <PostCard post={post} key={post.id} />
            )
          )}
        </div>
        <div className="lg:col-span-4 col-span-1 flex flex-top justify-center">
          <div className="lg:sticky relative top-8">
            <PostWidget />
            <Categories />
          </div>
        </div>

      </div>
    </div>
  );
}

export default CategoryBlog