import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { LogoIcon } from '../assets/icons';

const Navbar = () => {

  const [openMenu, setOpenMenu] = useState(false);

  return (
    
    <nav className="bg-gray-800 w-full fixed top-0 z-10">
      <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* <!-- Mobile menu button--> */}
            <button type="button" className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false"
            onClick={()=> setOpenMenu((prev) => !prev)}
            >
              <span className="sr-only">Open main menu</span>
              <svg className={`${openMenu ? 'hidden' : 'block'} h-6 w-6`} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              <svg className={`${openMenu ? 'block' : 'hidden'} h-6 w-6`} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center justify-center ml-8 lg:ml-24">
              <LogoIcon className="block h-8 w-auto lg:hidden"/>
              <Link to={'/'} className="text-white ml-4 uppercase text-xl"><span className="hidden md:inline">Chris's </span>Blog & Profile</Link>
            </div>
            <div className="hidden sm:ml-6 sm:block w-full">
              <div className="flex items-center justify-end mr-24">
                <NavLink to={"/"} className={({isActive}) => { return 'rounded-md px-3 py-2 text-md font-medium ' + (isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white")}} aria-current="page">Blog</NavLink>

                <NavLink to={"/profile"} className={({isActive}) => { return 'rounded-md px-3 py-2 text-md font-medium ' + (isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white")}} aria-current="page">Profile</NavLink>

                <NavLink to={"https://github.com/shinchri"} target="_blank" className={({isActive}) => { return 'rounded-md px-3 py-2 text-md font-medium ' + (isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white")}} aria-current="page">GitHub</NavLink>

                <NavLink to={"https://www.linkedin.com/in/shinchri/"} target="_blank" className={({isActive}) => { return 'rounded-md px-3 py-2 text-md font-medium ' + (isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white")}} aria-current="page">LinkedIn</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      <div className={`${openMenu ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2">
          <NavLink to={"/"} className={({isActive}) => { return 'block rounded-md px-3 py-2 text-base font-medium ' + (isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white")}}>Blog</NavLink>
          <NavLink to={"/profile"} className={({isActive}) => { return 'block rounded-md px-3 py-2 text-base font-medium ' + (isActive ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white")}}>Profile</NavLink>
        </div>
      </div>
    </nav>


  )
}

export default Navbar